import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { useEventDetails } from './useEventDetails';
import EventDetailsInfo from './EventDetailsInfo/EventDetailsInfo';
import EventDetailsTeams from './EventDetailsTeams';
import EventHeader from './EventHeader';
import VoteEvent from './VoteEvent/VoteEvent';
import { Tabs, Dialog, GenericError } from '../common';
import EventBoard from './EventBoard/EventBoard';
import { useTranslations } from '../../hooks/translations/useTranslations';
import EventJoinRequests from './EventRequests/EventJoinRequests';

import './eventDetails.css';

const EventDetails = () => {
    const auth = useAppSelector(state => state.auth);
    const { eventId } = useParams();
    const navigate = useNavigate();
    const t = useTranslations();

    const {
        isLoading,
        isEventLoading,
        eventDetails,
        error,
        joinEvent,
        leaveEvent,
        requestToJoin,
        hasUserJoined,
        foundUserInTeam,
        cancelEvent,
        voteEvent,
        refreshEventData,
    } = useEventDetails(eventId);

    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [showLeaveDialog, setShowLeaveDialog] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Details');

    const sections = [
        t('eventDetails.firstTab'),
        t('eventDetails.secondTab'),
        ...(eventDetails?.isPrivate ? ['Requests'] : []),
    ];

    // TODO: loader
    if (isEventLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <GenericError />;
    }

    if (!eventDetails) {
        return null;
    }

    const joinEventHandler = (teamId: string) => {
        joinEvent(teamId);
    };

    const onRequestAction = (changeTab?: boolean) => {
        refreshEventData();
        if (changeTab) {
            setSelectedTab('Details');
        }
    };

    const isOwner = eventDetails.owner.id === auth.authData?.id;
    const showVoteTools =
        eventDetails.isVotingPhase &&
        hasUserJoined &&
        !foundUserInTeam?.hasVoted &&
        !foundUserInTeam?.reported;

    const showJoinEventButton = eventDetails.isPrivate
        ? eventDetails.isPrivate && eventDetails.owner.id === auth.authData?.id
        : true;

    const onShowCancelDialog = () => {
        setShowCancelDialog(true);
    };

    const onShowLeaveDialog = () => {
        setShowLeaveDialog(true);
    };

    const onCancelEvent = () => {
        cancelEvent();
        setShowCancelDialog(false);
    };

    const onLeaveEvent = () => {
        leaveEvent();
        setShowLeaveDialog(false);
    };

    const onChangeTab = (tab: string) => {
        setSelectedTab(tab);
    };

    const onGoToEditPage = () => {
        navigate(`/events/${eventDetails.id}/edit`);
    };

    return (
        <div className="event-details">
            <EventHeader
                eventDetails={eventDetails}
                hasUserJoined={hasUserJoined}
                onShowCancelDialog={onShowCancelDialog}
                onLeaveEvent={onShowLeaveDialog}
                onRequestToJoin={requestToJoin}
                onGoToEditPage={onGoToEditPage}
                refreshEventData={refreshEventData}
            />
            <div
                className={`event-details-main ${
                    eventDetails.isCancelled || eventDetails.isCancelledBySystem
                        ? 'disabled'
                        : ''
                }`}
            >
                <Tabs
                    sections={sections}
                    selectedTab={selectedTab}
                    onChangeTab={onChangeTab}
                    dotIndex={eventDetails.joinRequests.length > 0 ? 2 : -1}
                />
                <div className="event-details-section">
                    {selectedTab === t('eventDetails.firstTab') && (
                        <>
                            <EventDetailsInfo eventDetails={eventDetails} />
                            {showVoteTools ? (
                                <VoteEvent
                                    eventDetails={eventDetails}
                                    onConfirmVote={voteEvent}
                                    isLoading={isLoading}
                                    foundUserInTeam={foundUserInTeam}
                                />
                            ) : (
                                <div className="event-details-teams-wrapper">
                                    <EventDetailsTeams
                                        eventDetails={eventDetails}
                                        onJoinEvent={joinEventHandler}
                                        showJoinEventButton={
                                            showJoinEventButton &&
                                            !eventDetails.isCancelled
                                        }
                                        isLoading={isLoading}
                                        isOwner={isOwner}
                                        refreshEventData={refreshEventData}
                                        foundUserInTeam={foundUserInTeam}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {selectedTab === t('eventDetails.secondTab') && (
                        <EventBoard
                            isEventCancelled={eventDetails.isCancelled}
                            isCancelledBySystem={
                                eventDetails.isCancelledBySystem
                            }
                        />
                    )}
                    {selectedTab === 'Requests' && (
                        <EventJoinRequests
                            eventDetails={eventDetails}
                            onRequestAction={onRequestAction}
                            isOwner={isOwner}
                        />
                    )}
                </div>
            </div>
            <Dialog
                isOpen={showCancelDialog}
                title={t('eventDetails.cancelDialogTitle')}
                bodyText={t('eventDetails.cancelDialogBody')}
                onClose={() => setShowCancelDialog(false)}
                onConfirm={onCancelEvent}
                isWarning
            />
            <Dialog
                isOpen={showLeaveDialog}
                title={t('eventDetails.leaveDialogTitle')}
                bodyText={t('eventDetails.leaveDialogBody')}
                onClose={() => setShowLeaveDialog(false)}
                onConfirm={onLeaveEvent}
                isWarning
            />
        </div>
    );
};

export default EventDetails;
