import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    fetchEventDetails,
    joinEventAPI,
    leaveEventAPI,
    requestJoinAPI,
    cancelEventAPI,
    changeTeamAPI,
    startVotingPhaseAPI,
    voteEventAPI,
} from '../../api/events';
import { useAppSelector } from '../../store/hooks';
import { IEvent } from '../../utils/types';
import { useSnackbar } from 'notistack';

export const useEventDetails = (eventId: string | undefined) => {
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAppSelector(state => state.auth);
    const [isEventLoading, setEventIsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState<IEvent | null>(null);
    const [error, setError] = useState(null);

    const fetchEventData = useCallback(async () => {
        if (eventId) {
            try {
                setEventIsLoading(true);
                const data = await fetchEventDetails(eventId);
                setEventDetails(data);
            } catch (error: any) {
                console.warn(error);
                setError(error);
            }
            setEventIsLoading(false);
        }
    }, [eventId]);

    useEffect(() => {
        fetchEventData();
    }, [fetchEventData]);

    const joinEvent = async (teamName: string) => {
        try {
            if (eventId && teamName) {
                setIsLoading(true);
                const data = await joinEventAPI(eventId, teamName);
                setEventDetails(data);
                enqueueSnackbar('Event team joined successfully.', {
                    variant: 'success',
                });
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const cancelEvent = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await cancelEventAPI(eventId as string);
            setEventDetails(data);
            enqueueSnackbar('Event cancelled successfully.', {
                variant: 'success',
            });
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    }, [eventId]);

    const leaveEvent = async () => {
        try {
            if (eventId) {
                setIsLoading(true);
                const data = await leaveEventAPI(eventId);
                setEventDetails(data);
                enqueueSnackbar('Event left successfully.', {
                    variant: 'success',
                });
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const hasUserJoined = useMemo(() => {
        const userTeam = eventDetails?.teams.find(team =>
            team.users.some(user => user.id === auth.authData?.id)
        );
        return !!userTeam;
    }, [eventDetails, auth]);

    const foundUserInTeam = useMemo(() => {
        const userTeam = eventDetails?.teams.find(team =>
            team.users.some(user => user.id === auth.authData?.id)
        );
        return userTeam?.users.find(user => user.id === auth.authData?.id);
    }, [eventDetails, auth]);

    const requestToJoin = async () => {
        try {
            if (eventId) {
                setIsLoading(true);
                const data = await requestJoinAPI(eventId);
                setEventDetails(data);
                enqueueSnackbar('Request to join created successfully.', {
                    variant: 'success',
                });
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const onNewBoardMessage = (updatedEvent: IEvent) => {
        setEventDetails(updatedEvent);
    };

    const onStartVotingPhase = async () => {
        setIsLoading(true);
        try {
            if (eventId) {
                const event = await startVotingPhaseAPI(eventId);

                setEventDetails(event);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const voteEvent = useCallback(
        async (likes: string[], winner: string | null) => {
            if (eventId) {
                try {
                    setIsLoading(true);
                    const data = await voteEventAPI(eventId, likes, winner);
                    setEventDetails(data);
                    enqueueSnackbar('Vote registered.', {
                        variant: 'success',
                    });
                } catch (error: any) {
                    console.warn(error);
                    setError(error);
                }
                setIsLoading(false);
            }
        },
        [eventId]
    );

    function refreshEventData() {
        fetchEventData();
    }

    return {
        isLoading,
        isEventLoading,
        eventDetails,
        error,
        joinEvent,
        leaveEvent,
        hasUserJoined,
        foundUserInTeam,
        requestToJoin,
        cancelEvent,
        onStartVotingPhase,
        onNewBoardMessage,
        voteEvent,
        refreshEventData,
    };
};
